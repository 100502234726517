import React from "react";
import "./Navbar.css";
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { useState } from "react";
import Typewriter from "typewriter-effect";

const Navbar = () => {
  const [toogle, setToogle] = useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <Typewriter
          options={{
            strings: "A.MARCATO",
            autoStart: true,
            cursor: "_",
            delay: 800,
          }}
          className="app__navbar-typewritter"
        />
      </div>
      <ul className="app__navbar-links">
        {[
          "Accueil",
          "Formations",
          "Projets",
          "Compétences",
          "Retours",
          "Contact",
        ].map((item) => (
          <li key={`link-${item}`} className="app__flex p-text">
            <div />
            <a href={`#${item.toLowerCase()}`}>{item}</a>
          </li>
        ))}
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4
          onClick={() => {
            setToogle(true);
          }}
        />
        {toogle && (
          <motion.div
            whileInView={{ x: [200, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToogle(false)} />
            <ul className="app__navbar-links">
              {[
                "Accueil",
                "Formations",
                "Projets",
                "Compétences",
                "Retours",
                "Contact",
              ].map((item) => (
                <li key={item} className="app__flex p-text">
                  <a
                    href={`#${item.toLowerCase()}`}
                    onClick={() => setToogle(false)}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
