import React, { useState } from "react";
import { TbMailForward, TbPhoneCall } from "react-icons/tb";
import emailjs from "@emailjs/browser";
import { AppWrap, MotionWrap } from "../../wrapper";

import "./Footer.css";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, email, message } = formData;
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateId = "template_f131u8m";
    const serviceId = "service_7sigv4n";
    sendFeedback(serviceId, templateId, {
      name: name,
      email: email,
      message: message,
    });
  };

  const sendFeedback = (serviceId, templateId, vars) => {
    if (name === "" || email === "" || message === "") {
      alert("Merci de remplir correctement les champs! ❌");
      setLoading(false);
    } else {
      emailjs
        .send(serviceId, templateId, vars, "NhfxaCFubCrA3TdwE")
        .then((res) => {
          setLoading(false);
          setIsFormSubmitted(true);
        })
        .catch((err) => {
          console.log("Erreur : " + err);
        });
    }
  };
  return (
    <>
      <h2 className="head-text">
        Prenons un café <span>ensemble</span> ☕
      </h2>
      <div className="app__footer-cards">
        <div className="app__footer-card">
          <TbMailForward />
          <a href="mailto:contact@anthony-marcato.fr" className="p-text">
            contact@anthony-marcato.fr
          </a>
        </div>
        <div className="app__footer-card">
          <TbPhoneCall />
          <a href="tel:0627827146" className="p-text">
            06 27 82 71 46
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <form className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Nom"
              value={name}
              name="name"
              onChange={handleChangeInput}
              required
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Email"
              value={email}
              name="email"
              onChange={handleChangeInput}
              required
            />
          </div>
          <div>
            <textarea
              className="p-text"
              name="message"
              onChange={handleChangeInput}
              value={message}
              placeholder="Message..."
              required
            />
          </div>
          <button type="submit" className="p-text" onClick={handleSubmit}>
            {loading ? "Envoi..." : "Envoyer le message  🚀"}
          </button>
        </form>
      ) : (
        <div className="valid">
          <h3>Merci pour votre message!</h3>
          <h3>Je vous recontacte au plus tôt ! 👌</h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
