import React from "react";
import { BsGithub } from "react-icons/bs";
import { FaFacebook } from "react-icons/fa";
import { HiMail } from "react-icons/hi";

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a href="https://www.facebook.com/Marcattt">
        <div>
          <FaFacebook />
        </div>
      </a>
      <a href="https://github.com/Marcat-A">
        <div>
          <BsGithub />
        </div>
      </a>
      <a href="mailto:contact@anthony-marcato.fr">
        <div>
          <HiMail />
        </div>
      </a>
    </div>
  );
};

export default SocialMedia;
